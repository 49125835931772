/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root{
    --accessories-tab-background-color: #e7e3e3;
    --related-first-swatch-border-color: #cac5c5;
    --related-option-color: #9b9b9b;
    --show-more-button-background-color: #ed4f36;
}

.ProductLinks {
    max-width: 100%;

    @include mobile {
        margin-top: 1.4rem;
        border-bottom: 1px solid var(--primary-divider-color);
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Card {
        padding: 0 7px;
        margin-bottom: 10px;
    }

    &-Wrapper {
        padding-bottom: 0;
        padding-top: 0;

        @include mobile {
            height: 580px;
        }

        &_linkType {
            &_upsell {
                @include mobile {
                    height: unset;
                }
            }
        }

        &_linkType {
            &_related {
                @include mobile {
                    height: unset;
                }
            }
        }
    }

    &-TooltipWrapper {
        position: absolute;
        z-index: 10;
    }

    &-TitleWrapper {
        margin: 10px auto;
        margin-top: 30px;
        border-bottom: 1px solid rgba(151,151,152,.26);
        padding-bottom: 15px;
        text-align: center;
    }

    &-TitleSubWrapper {
        display: flex
    }

    &-Title {
        font-size: 2.3rem;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 5px;

        @include after-mobile {
            font-weight: bold;
        }
    }

    &-CrossTitle {
        color: #000000;
        font-family: Lato;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }

    &-FiltersWrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 12px;
        column-gap: 7px;
        row-gap: 7px;

        &_isCartPopup {
            padding: 0;
            margin: 15px 0;

            @include mobile {
                grid-template-columns: repeat(4, minmax(0, 1fr));
            }
        }
    }

    &-FilterButton {
        box-sizing: border-box;
        min-width: fit-content;
        border: 1px solid black;
        border-radius: 16px;
        color: black;
        font-family: Lato;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 14px;
        padding: 7px;

        &_isActive {
            background-color: black;
            color: white;
        }
    }

    &-List{
        display: flex;
        justify-content: center;

        &_linkType_related {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));

            @include mobile {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }
        }
    }

    &-ShowMore {
        background-color: var(--show-more-button-background-color);
        border: 1px solid var(--show-more-button-background-color);
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 16px;
        padding: 8px 12px;
        margin-left: 10px;
        transition: all .2s ease-out;

        @include mobile {
            margin: 9px;
        }
    }

    &-ColorsSubTitle {
        color: var(--related-option-color);
        font-size: 1.6rem;
        margin-left: 15px;
        margin-top: 25px;
    }

    &-ViewSuggestion {
        color: var(--product-configurable-attributes-option-color);
        font-size: 1.4rem;
        float: right;
        margin-top: 25px;

        @include mobile {
            display: none;
        }
    }

    &-Upsell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
        height: 100%;
        padding-bottom: 20px;

        @include mobile {
            justify-content: center;
        }

        &Heading {
            padding: 0 10px;
            font-size: 1.8rem;
            margin-top: 2.16rem;
            margin-bottom: 1.8rem;
            font-weight: bold;
        }

        &Card {
            display: block;
            margin-bottom: 0;
        }

        li:last-child {
            margin-bottom: 0;
        }

        .ProductCard {
            padding: 10px;
            width: 150px;

            &-ProductName {
                color: black;
            }
        }
    }

    &-Crosssell {
        display: flex;
        padding: 25px;
        padding-left: 15px;
        margin: 0;
        border-bottom: 1px solid #F4F4F4;

        &_isRequired {
            background-color: white;
        }

        &_isCartPopup {
            padding: 0;
            padding-right: 15px;
            border-bottom: unset;
        }

        .ProductCard-AccessorySizeTooltip {
            .Tooltip-Wrapper {
                top: 10px;
                left: 0;
                bottom: unset;
                transform: translateX(-50%);
                box-shadow: 3px 3px 15px #adadad;
                max-height: 90px;
                overflow-y: scroll;
                color: black;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                }

                &::-webkit-scrollbar:vertical {
                    width: 8px;
                }

                &::-webkit-scrollbar:horizontal {
                    height: 11px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    border: 2px solid white;
                    background-color: #c0c0c0;
                }

                @include mobile {
                    top: 20px;
                }
            }
        }

        &:last-of-type {
            .ProductCard-AccessorySizeTooltip {
                .Tooltip-Wrapper {
                    top: unset;
                    left: 0;
                    bottom: 10px;
                    transform: translateX(-50%);
                    box-shadow: 3px 3px 15px #adadad;

                    @include mobile {
                        bottom: 20px;
                    }
                }
            }
        }
        
        &:first-of-type {
            .ProductCard-AccessorySizeTooltip {
                .Tooltip-Wrapper {
                    top: 10px;
                    left: 0;
                    bottom: unset;
                    transform: translateX(-50%);
                    box-shadow: 3px 3px 15px #adadad;

                    @include mobile {
                        top: 18px;
                        max-height: 80px;
                    }
                }
            }
        }
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }

    .ExpandableContent {
        margin-bottom: 0;
    }

    &_isCrossSellSectionLoading {
        .ContentWrapper {
            height: 150px;
        }
    }

    &_isCrossSellSection {
        margin-top: 8px;
        min-height: 150px;
        background-color: white;

        .ProductLinks-ExpandableContentHeading {
            @include desktop {
                display: none;
            }
        }

        .ProductLinks-ExpandableContentButton {
            @include mobile {
                background-color: var(--primary-base-color);
            }
        }

        .ProductLinks-Content {
            border-bottom: 0;

            .ExpandableContent-Content_isContentExpanded {
                background-color: var(--accessories-tab-background-color);
            }

            .ExpandableContent-Button_isContentExpanded {
                background-color: var(--accessories-tab-background-color);
            }
        }

        .ProductLinks-Wrapper {
            padding: 0;
        }
    }

    &_isCartPopup {
        width: 880px;
        min-height: 460px;

        @include mobile {
            width: 100%;
            min-height: 800px;
        }
    }

    &-NoSelection {
        color: #888888;
        font-family: Lato;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        margin-top: 30px;
    }

    &-RelatedCard {
        justify-content: center;
    }

    &-Accessories {
        width: 100%;
        max-height: 500px;
        overflow-y: scroll;

        &_isCartPopup {
            overflow-y: unset;
            max-height: none;
        }
    }

    .CarouselScroll {
        height: 100%;
        overflow-x: hidden;

        &-Content {
            margin: 5px
        }

        .CarouselScrollItem {
            height: 100%;
            width: var(--carousel-item-width);
            margin: 0;
        }

        .CarouselScrollArrow {
            top: -10px;
        }

        .ProductCard {
            width: 100%;
            display: block;

            &-FlooringType {
                min-height: auto;
            }

            &-Sku {
                color: black;
            }
        }

        .ProductCard:first-child a {
            pointer-events: all;
            border: none;
            padding: 0;
        }
    }
}
