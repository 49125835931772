/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --checkout-progress-bar-background: #F2F2F2;
    --checkout-progress-bar-active-color: #e53f25;
}

.CheckoutProgressBar {
    background-color: var(--checkout-progress-bar-background);
    padding-bottom: 20px;

    &-Wrapper {
        display: inline-flex;
        width: 100%;
        text-align: center;
        margin-top: 50px;
    }

    &-Cart,
    &-Shipping,
    &-Billing,
    &-Payment {
        width: 100%;
        padding-top: 45px;
        font-weight: 400;
        font-size: 1.8rem;

        &:before {
            top: 19px;
            content: '';
            height: 7px;
            left: 0;
            position: absolute;
            width: 100%;
            background-color: black;
            border: 1px solid black;
            height: 5px;
            -webkit-transition: background-color 1s ease-out;
            -moz-transition: background-color 1s ease-out;
            -o-transition: background-color 1s ease-out;
            transition: background-color 1s ease-out;
        }

        &:after {
            background: white;
            border-color: white;
            content: '';
            height: 30px;
            top: 5px;
            border-radius: 50%;
            margin-left: -14px;
            width: 30px;
            line-height: 1.7;
            left: 50%;
            position: absolute;
        }

        span {

            &:before {
                background-color: black;
                border: 1px solid black;
                height: 38px;
                margin-top: -45px;
                margin-left: -19px;
                width: 38px;
                border-radius: 50%;
                content: '';
                left: 50%;
                position: absolute;
                top: 0;
                -webkit-transition: background-color 1s ease-out;
                -moz-transition: background-color 1s ease-out;
                -o-transition: background-color 1s ease-out;
                transition: background-color 1s ease-out;
            }

            &:after {
                content: '';
                display: inline-block;
                transform: rotate(45deg);
                height: 20px;
                width: 8px;
                border-bottom: 2px solid black;
                border-right: 2px solid black;
                position: absolute;
                left: 50%;
                margin-left: -4px;
                top: 0px;
                margin-top: -38px;
                z-index: 1;
                opacity: 0;
            }
        }

        &_status_active {
            a {
                color: var(--checkout-progress-bar-active-color);
            }

            &:before {
                background-color: var(--checkout-progress-bar-active-color);
                border-color: var(--checkout-progress-bar-active-color);
                -webkit-transition: background-color 1s ease-out;
                -moz-transition: background-color 1s ease-out;
                -o-transition: background-color 1s ease-out;
                transition: background-color 1s ease-out;
            }

            span {
                &:before {
                    background-color: var(--checkout-progress-bar-active-color);
                    border-color: var(--checkout-progress-bar-active-color);
                    -webkit-transition: background-color 1s ease-out;
                    -moz-transition: background-color 1s ease-out;
                    -o-transition: background-color 1s ease-out;
                    transition: background-color 1s ease-out;
                }

                &:after {
                    border-color: var(--checkout-progress-bar-active-color);
                    opacity: 1;
                }
            }
        }

        &_status_completed {
            &:before {
                background: var(--checkout-progress-bar-active-color);
                border-color: var(--checkout-progress-bar-active-color);
            }

            span {
                &:before {
                    background: var(--checkout-progress-bar-active-color);
                    border-color: var(--checkout-progress-bar-active-color);
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }
}
